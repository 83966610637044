<template>
  <div class="overlay">
    <div id="interface">
      <button class="buttons" id="btnTL" v-on:click="toggleAudio">Mute</button>
      <!--      <label for="show-menu" class="show-menu">Work</label>
            <input type="checkbox" id="show-menu">-->
      <button class="buttons" id="btnTR" v-on:click="toggleProjects">Work</button>
      <button class="buttons" id="btnBR" v-on:click="toggleContact">Contact</button>
      <button class="buttons" id="btnBL" v-on:click="toggleAbout">About</button>
    </div>
    <div class="work-types" id="work-types">
      <ul class="work-navigation">
        <li id="locationSound">
          <router-link to="/production-sound">Production Sound</router-link>
        </li>
        <li id="soundDesign">
          <router-link to="/editorial-sound">Editorial Sound</router-link>
        </li>
        <li id="music">
          <router-link to="/music">Music</router-link>
        </li>
      </ul>
    </div>
    <div v-if="displayAbout">
      <About v-on:closed="toggleAbout"/>
    </div>
    <div v-if="displayContact">
      <Contact v-on:closed="toggleContact"/>
    </div>
  </div>
</template>

<script>
import About from "@/components/About";
import Contact from "@/components/Contact";

export default {
  components: {About, Contact},

  data() {
    return {
      projectsOut: false,
      displayAbout: false,
      displayContact: false,
      audioButton: null,
      audioPlaying: true

    }
  },
  methods: {
    toggleProjects() {
      if (!this.projectsOut) {
        document.getElementById('work-types').style.display = 'block'
      } else {
        document.getElementById('work-types').style.display = 'none'
      }
      this.projectsOut = !this.projectsOut
    },

    toggleAbout() {
      this.displayAbout = !this.displayAbout
      this.toggleAudio ()
    },
    toggleContact() {
      this.displayContact = !this.displayContact
      this.toggleAudio ()
    },
    toggleAudio () {
      //document.getElementById("btnTL").innerHTML = 'Lock';


      if (this.audioPlaying) {
        this.$emit('audioPressed', false)
        this.audioButton.style.color = '#808080'
        this.audioPlaying = false
        this.$store.dispatch("setAudio", "false");
      } else {
        this.audioButton.style.color = '#ffffff'
        this.$emit('audioPressed', true)
        this.audioPlaying = true
        this.$store.dispatch("setAudio", "true");

      }
    }
  },
  mounted() {
    window.addEventListener('ontouchend', (() => {
      document.getElementById("btnTL").innerHTML = 'Lock';
    }))
    this.audioButton = document.getElementById('btnTL')
    if (this.isAudio == 'false'){
      //this.$emit('audioPressed', false)
      this.audioButton.style.color = '#808080'
      this.audioPlaying = false
    }

  },

computed: {
  isAudio() {
    return this.$store.getters.playingAudio;
  },

}
}
</script>

<style scoped>

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  color: #fff;


  line-height: 24px;
  overscroll-behavior: none;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
  text-transform: uppercase;
}

canvas {
  display: block;
}

/*
#info {
	position: absolute;
	top: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1; !* TODO Solve this in HTML *!
}
*/


.dg.ac {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2 !important; /* TODO Solve this in HTML */
}

.overlay {
  /*  font-family: 'Rubik';*/
  font-weight: normal;
  font-style: normal;
  font-size: 26px;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /*CRITICAL TO STOP CANVAS FROM BLOCKING RAYCASTS*/
  /*
	opacity: 1;
  */
  /*
	background-color:rgba(0, 0, 0, 0.7);
  */
  background: transparent;
  color: #ffffff;
}

a, button, input, select, span {
  pointer-events: auto;
}

#overlay > div {
  text-align: center;
}

#overlay > div > p {
  color: #777777;
  font-size: 12px;
}


#interface {
  opacity: 1;
  -webkit-transition: opacity 1s ease-out;
  -moz-transition: opacity 1s ease-out;
  -o-transition: opacity 1s ease-out;
  -ms-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out;
}

.buttons {
  /* font-family: 'Rubik';*/
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  position: absolute;
  background-color: transparent;
  color: #fff;
  border-radius: 8px;
  border: none;
  padding: 10px;
  transform: scale(1);
  transition: all 0.5s ease-in;
  pointer-events: auto;
}

.buttons:hover {
  transform: scale(1.5);
  /*
  transition-duration: 0.75s;
  */
  transition: all 0.5s ease;
}

#btnBL {
  bottom: 30px;
  right: 30px;
  height: 40px;
}

#btnBR {
  bottom: 30px;
  left: 30px;
  height: 40px;
}


#btnTR {
  top: 30px;
  right: 30px;
  height: 40px;
}


#btnTL {
  top: 30px;
  left: 30px;
  height: 40px;
  color: #ffffff;
}

#locationSound {
  background: transparent;
}


/* PROJECT TYPES */
.work-types {
  height: 250px;
  width: 250px;
  position: fixed;
  display: none;
  z-index: 2;
  top: 0px;
  right: 100px;
  background-color: transparent;
  overflow: hidden;
  transition: all 0.5s ease-out;

}

.work-navigation {
  list-style: none;
  color: #fff;
  font-size: 1.25rem;
  padding: 1rem;
}

/*
.work-navigation > ul > li a {
  color: #fff;;
  text-decoration: none;
}
*/



/* EMAIL FORM */

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.email-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

/* DISPLAYING ON CANVAS */

#drawing-canvas {
  position: absolute;
  background-color: #000000;
  top: 0px;
  right: 0px;
  z-index: 3000;
  cursor: crosshair;
}

#text-canvas {
  position: absolute;
  visibility: hidden;
  background-color: #000000;
  top: 0px;
  right: 0px;
  z-index: 3000;
  cursor: crosshair;
}


</style>